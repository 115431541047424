<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Products for Sale" class="bg-green text-white">
                <div class="col-md-12">
                    <stats-card card-title="Products" card-sub-title="Store Products">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-8">
                    <show-card v-if="showRecordPage" :cardTitle="showRecordData.name" :cardSubTitle="'Product Name'" >  
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th><i class="fas fa-file-image"></i> &nbsp; &nbsp; Image</th>
                                        <th><img :src="showRecordData.images" height="50"/></th>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-fish"></i> &nbsp; &nbsp; SKU</th>
                                        <td>{{showRecordData.sku}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-tag"></i> &nbsp; &nbsp; Category</th>
                                        <td>{{showRecordData.category}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-calculator"></i> &nbsp; &nbsp; Qty Left</th>
                                        <td>{{showRecordData.qty}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-weight"></i> &nbsp; &nbsp; Unit of Measurement</th>
                                        <td>{{showRecordData.unit}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-receipt"></i> &nbsp; &nbsp; Cost Price</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.cost_price.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hand-holding-usd"></i> &nbsp; &nbsp; Sale Price</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.sale_price.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-coins"></i> &nbsp; &nbsp; Discount Price</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.discount_price.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-info-circle"></i> &nbsp; &nbsp; Description</th>
                                        <td>{{showRecordData.description}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-toggle-on"></i> &nbsp; &nbsp; Status</th>
                                        <td>{{showRecordData.status}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-clock"></i> &nbsp; &nbsp; Created On</th>
                                        <td>{{showRecordData.created_at}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-clock"></i> &nbsp; &nbsp; Updated</th>
                                        <td>{{showRecordData.updated_at}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                         ..
                        <div class="text-right">
                            <button @click="openUpdateForm(showRecordData)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
                            <button type="button" @click="closeShowRecord" class="btn btn-light btn-sm"><i class="fas fa-window-close"></i> close</button>
                        </div>
                    </show-card>
                    <table-card card-title="All Products" v-else>
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Qty Left</th>
                                <th scope="col">Price</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.product_id">
                                <td><img :src="record.images" height="50"/></td>
                                <td><span v-if="record.status == 'deactivated'"><em><del>{{ record.name }}</del></em></span> <span v-else>{{ record.name }}</span></td>
                                <td>{{ record.qty }} {{ record.unit }}</td>
                                <td>{{ record.currency }} {{ record.discount_price }}</td>
                                <td>
                                    <button @click="openUpdateForm(record)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
                                    <button @click="openShowRecord(record)" class="btn btn-dark btn-sm"><i class="fas fa-eye"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-4">
                    <form-card :card-title="showUpdateForm ? 'Update Form' : 'New Form'" :card-sub-title="showUpdateForm ? 'Update Product Info' : 'Create New Product'">
                        <form v-on:submit.prevent>
                            <div class="form-group">
                                <div>
                                    <label for="">Product Name</label>
                                    <input class="form-control" type="text" v-model="form.name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">SKU (optional)</label>
                                    <input class="form-control" type="text" v-model="form.sku">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Short Details (optional)</label>
                                    <input class="form-control" type="text" v-model="form.description">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Category</label>
                                    <select class="form-control" v-model="form.category">
                                        <option v-for="option in category" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div v-if="showUpdateForm">
                                    <label for="">Increase Qty left by</label>
                                    <input class="form-control" type="number" min="0" onwheel="this.blur()" v-model="form.new_stock_qty">
                                </div>
                                <div v-else>
                                    <label for="">Opening Qty</label>
                                    <input class="form-control" type="number" min="0" onwheel="this.blur()" v-model="form.qty">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Unit</label>
                                    <select class="form-control" v-model="form.unit">
                                        <option v-for="option in units" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Cost Price (optional)</label>
                                    <input class="form-control" type="number" min="0" onwheel="this.blur()" v-model="form.cost_price">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Sale Price </label>
                                    <input class="form-control" type="number" min="0" onwheel="this.blur()" v-model="form.sale_price">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Discount Price (optional)</label>
                                    <input class="form-control" type="number" min="0" onwheel="this.blur()" v-model="form.discount_price" >
                                </div>
                            </div>
                            <div class="form-group" v-if="showUpdateForm">
                                <div>
                                    <label for="">Status</label>
                                    <select class="form-control" v-model="form.status">
                                        <option v-for="option in ['active','deactivated']" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Image</label>
                                    <input type="file" accept="image/*" class="form-control"/>
                                </div>
                            </div>
                            
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4" v-if="showUpdateForm" @click="updateForm">Update</button>
                                <button v-else type="submit" class="btn btn-primary my-4" @click="createForm">Create</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
import TwoButtonColorCardVue from '../../components/Cards/TwoButtonColorCard.vue';
export default {
    name: 'Products',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue, 
        'show-card': TwoButtonColorCardVue,
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    mounted(){
        if (this.authToken) {
            this.getRecords()
        }
        else{
            this.$router.push('/login')
        }
    },
    data() {
        return{
            records: [],
            category : ['fish', 'poultry'],
            units : ['pieces','cartons','crates','sets', 'kg','meters','pairs'],
            showUpdateForm: false,
            showRecordPage: false,
            form:{}
        }
    },
    methods:{
        uploadImage (recordId) {
            if (document.querySelector('input[type=file]').files[0]){
                let formdata = new FormData()
                formdata.append('images', document.querySelector('input[type=file]').files[0])
                formdata.append('id', recordId)
                axios.post(process.env.VUE_APP_BASEURL+'/shop/products',formdata,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'Content-Type' : 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.getRecords()
                })
            }
        },
        createForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/shop/products', this.form,
            { 
                headers: { 
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.uploadImage(response.data.data.product_id)
                this.getRecords()
            })
            .catch((error) => {
                // error.response.status Check status code
                    this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        },

        updateForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/shop/products/'+this.recordId+'?_method=PUT', this.form,
            { 
                 headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(() => {
                //Perform Success Action
                this.$moshaToast('Update Successful!',{
                    type: 'success'
                });
                this.uploadImage(this.recordId)
                this.getRecords()
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/shop/products?admin='+1,
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(response => {
                this.records = response.data.data
                this.showRecordPage = false
                this.form = {}
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
                if (error.response.status === 401) {
                    localStorage.removeItem('mytoken');
                    this.$router.push('/login');
                }
            })
        },

        openUpdateForm(record){
            this.showUpdateForm = true,
            this.recordId = record.product_id,
            this.form.name = record.name,
            this.form.sku = record.sku,
            this.form.qty = record.qty,
            this.form.category = record.category,
            this.form.unit = record.unit,
            this.form.cost_price = record.cost_price,
            this.form.sale_price = record.sale_price,
            this.form.discount_price = record.discount_price,
            this.form.description = record.description
            this.form.status = record.status
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form = {}
        },
        openShowRecord(record){
            this.showRecordPage = true,
            this.showRecordData = record
        },
        closeShowRecord(){
            this.showRecordPage = false
        }

    },
}
</script>